export const PARTIAL_SEARCH_PARAM = "fresh-partial";
export const PARTIAL_ATTR = "f-partial";
export const LOADING_ATTR = "f-loading";
export const CLIENT_NAV_ATTR = "f-client-nav";
export const DATA_KEY_ATTR = "data-fresh-key";
export const DATA_CURRENT = "data-current";
export const DATA_ANCESTOR = "data-ancestor";

export const enum PartialMode {
  REPLACE,
  APPEND,
  PREPEND,
}

// denoCacheMetadata={"headers":{"cache-control":"public, max-age=31536000, immutable","content-length":"386","via":"http/2 edgeproxy-h","x-amz-cf-pop":"LHR50-P6","access-control-allow-origin":"*","x-amz-cf-id":"yePMVqxViS3bqJdz3T2UVynHYVrZxhxtNTa6WHaqLrLxw1burlb4Fg==","x-amz-server-side-encryption":"AES256","cross-origin-opener-policy":"same-origin","x-cache":"Hit from cloudfront","x-content-type-options":"nosniff","last-modified":"Fri, 22 Mar 2024 21:26:14 GMT","etag":"\"cbf7306b660163352728761242054293\"","accept-ranges":"bytes","date":"Fri, 22 Mar 2024 21:43:13 GMT","x-amz-replication-status":"COMPLETED","x-frame-options":"DENY","x-amz-version-id":"706Vuf2AgHYe7CCucNHbXULbEOSyPiKM","server":"deno/gcp-europe-west2","strict-transport-security":"max-age=63072000; includeSubDomains; preload","referrer-policy":"strict-origin-when-cross-origin","vary":"Accept-Encoding, Origin","age":"18446925","cross-origin-resource-policy":"same-origin","server-timing":"fetchSource;dur=6","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","content-type":"application/typescript; charset=utf-8","cross-origin-embedder-policy":"same-origin"},"url":"https://deno.land/x/fresh@1.6.8/src/constants.ts","time":1729590717}