import { VNode } from "preact";
import { DATA_ANCESTOR, DATA_CURRENT } from "../constants.ts";

export const enum UrlMatchKind {
  None,
  Ancestor,
  Current,
}

export function matchesUrl(current: string, needle: string): UrlMatchKind {
  let href = new URL(needle, "http://localhost").pathname;
  if (href !== "/" && href.endsWith("/")) {
    href = href.slice(0, -1);
  }

  if (current !== "/" && current.endsWith("/")) {
    current = current.slice(0, -1);
  }

  if (current === href) {
    return UrlMatchKind.Current;
  } else if (current.startsWith(href + "/") || href === "/") {
    return UrlMatchKind.Ancestor;
  }

  return UrlMatchKind.None;
}

/**
 * Mark active or ancestor link
 * Note: This function is used both on the server and the client
 */
export function setActiveUrl(vnode: VNode, pathname: string): void {
  const props = vnode.props as Record<string, unknown>;
  const hrefProp = props.href;
  if (typeof hrefProp === "string" && hrefProp.startsWith("/")) {
    const match = matchesUrl(pathname, hrefProp);
    if (match === UrlMatchKind.Current) {
      props[DATA_CURRENT] = "true";
      props["aria-current"] = "page";
    } else if (match === UrlMatchKind.Ancestor) {
      props[DATA_ANCESTOR] = "true";
      props["aria-current"] = "true";
    }
  }
}

// denoCacheMetadata={"headers":{"server-timing":"fetchSource;dur=6","x-amz-server-side-encryption":"AES256","x-amz-version-id":"bG05Wb7RQ0MPFEXPWn3wzaO0UexUwZci","last-modified":"Fri, 22 Mar 2024 21:26:14 GMT","accept-ranges":"bytes","strict-transport-security":"max-age=63072000; includeSubDomains; preload","via":"http/2 edgeproxy-h","access-control-allow-origin":"*","referrer-policy":"strict-origin-when-cross-origin","cross-origin-resource-policy":"same-origin","x-cache":"Hit from cloudfront","content-length":"1290","x-content-type-options":"nosniff","cache-control":"public, max-age=31536000, immutable","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","cross-origin-opener-policy":"same-origin","age":"18446924","cross-origin-embedder-policy":"same-origin","x-amz-cf-id":"UOb0IQ2BFMULXxntqnjRuPV8KXtWJQJA4SIktFF_DwCh9gh6ynqT2A==","etag":"\"8c5875609ecffdbf761741a83dc583e1\"","x-amz-cf-pop":"LHR50-P6","x-frame-options":"DENY","content-type":"application/typescript; charset=utf-8","server":"deno/gcp-europe-west2","date":"Fri, 22 Mar 2024 21:43:14 GMT","vary":"Accept-Encoding, Origin","x-amz-replication-status":"COMPLETED"},"url":"https://deno.land/x/fresh@1.6.8/src/runtime/active_url.ts","time":1729590717}